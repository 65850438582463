<template>
  <v-dialog v-model="modalData.dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Site
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#232341"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row style="margin: 0">
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.site"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  v-model="formModel.name"
                  label="Site"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="formModel.country_id"
                  :items="countryCollection"
                  label="Select Country"
                  item-text="name"
                  item-value="id"
                  :rules="countryRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.contact_data[0].postcode"
                  label="Postcode"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="formModel.contact_data[0].city"
                  label="City"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  v-model="formModel.contact_data[0].street"
                  label="Street"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import i18nService from "@/core/services/i18n.service.js";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = {
  id: "",
  status: 1,
  name: "",
  country_id: "HU",
  employees: [],
  contact_data: [{}],
  // translations: {},
};

export default {
  name: "DepartmentForm",
  props: ["modalData", "statuses", "permissions", "loader"],
  data() {
    return {
      // languages: i18nService.languages,
      // selectedLocale: i18nService.languages[0],
      formModel: initialFormData,
      formValid: false,
      nameRules: [
        (v) => !!v || "Site is required",
        (v) => v.length > 2 || "Site must be min 3 characters",
      ],
      countryRules: [(v) => !!v || "Country is required"],
    };
  },
  computed: {
    ...mapGetters(["countryCollection"]),

    formTitle() {
      return this.modalData.editedIndex === -1 ? "New Site" : "Edit Site";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        // if (this.formModel.country.id) {
        //   this.formModel.country = this.formModel.country.id;
        // }
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    ...mapActions(["fetchCountry"]),
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (TRANSLATED_ATRIBUTES.length > 0) {
        if (newItem) {
          this.languages.forEach((language) => {
            TRANSLATED_ATRIBUTES.forEach((attribute) => {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            });
          });
        } else {
          this.languages.forEach((language) => {
            TRANSLATED_ATRIBUTES.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
                this.formModel.translations[language.lang][attribute] = "";
              }
            });
          });
        }
      }
    },
    handleNew() {
      this.formModel.name = "";
      this.formModel.country = "HU";
      this.setTranslatedAttributes(true);
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "companySettings.site." + action
      );
    },
  },
  async mounted() {
    this.fetchCountry();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
